import Gallery from '../components/Gallery'
import { useParams } from "react-router-dom";

function GalleryRoute() {
    let params = useParams();
    return (
        <div>
            <Gallery galleryName={params.galleryName}/>
        </div>
      );
}

export default GalleryRoute;