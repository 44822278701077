import React, {useState, useEffect} from 'react'
import { KeystoneData } from './KeystoneData';
import { Link } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';

const source = new KeystoneData();

function Image({gallery}) {
    return(
        <div className="galleryItemWrapperO" style={{margin:"auto"}}>
            <div className="galleryItemWrapperI" style={{width:"100%"}}>
                <Link to={"/gallery/" + gallery.name}>
                <img style={{objectFit:"cover",height:"100%",aspectRatio:"1"}} src={gallery.thumb_url} />
                </Link>
            </div>
            <div className="galleryTitle">
                <Link to={"/gallery/" + gallery.name} className="galleryTitle">{gallery.name}</Link>
            </div>
        </div>
    )
}

const img_host = "https://img.kylescholz.com/images/";
const img_host_thumbnail = "https://img.kylescholz.com/images/thumbnails/";

async function fetchGalleryList({ queryKey }) {
    const [_key, { }] = queryKey;

    return await source.queryGalleries().then((response) => {
        const galleryList = [];
        for (const gallery of response.data.galleries) {
            galleryList.push({
                id: gallery.id,
                name: gallery.name,
                className: "brick_square",
                thumb_url: img_host_thumbnail + gallery.images[0].filename,
                url: img_host + gallery.images[0].filename,
            });
        }
        return galleryList;
    });
}

function GalleryList() {

    const { status, data:galleries, error } = useQuery(['galleryList', {}], fetchGalleryList);

    return (
        <div className="container">
            {status === 'success' ? 
                <div className="wrapper">
                    <div className="sticky-header">
                        <span className="nav_title" style={{display:"inline-block"}}>Photo Galleries</span>
                        <div style={{float:"right", display:"inline-block", paddingRight:"10px"}}>
                        </div>
                    </div>
                    <div className="masonry" style={{width:"100%",justifyContent:"center"}}>
                        {galleries.map((gallery) => (
                            <Image key={gallery.id} gallery={gallery} />
                        ))}
                    </div>
                </div>
            : '' }
        </div>
    );

}

export default GalleryList
