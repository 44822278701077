import React, {useEffect} from 'react'
import { Outlet, Link } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: 0, //30 seconds
      refetchIntervalInBackground: false,
      suspense: false,
    },
  },
})

function App() {
  useEffect(() => {
    document.title = 'Photo Gallery';
  });

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <title>Photo Gallery</title>
        <nav>
          
        </nav>
        <Outlet />
        <div className="footer">
          Copyright 2022 Kyle Scholz.
        </div>
      </div>
      
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
