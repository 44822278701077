import GalleryList from '../components/GalleryList'

function GalleryListRoute() {
    return (
        <div>
            <GalleryList/>
        </div>
      );
}

export default GalleryListRoute;