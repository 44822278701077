let keystoneHost = "https://data.kylescholz.com/"
if (!window.location.hostname.includes("kylescholz.com")) {
    keystoneHost = window.location.protocol + '//' + window.location.hostname + ":8000/"
}

class KeystoneData {

    async postData(body) {
        const url = keystoneHost + "api/graphql";
        const options = {
            method: "POST", 
            headers: new Headers({'content-type': 'application/json'}),
            mode: 'cors', 
            credentials: 'include',
            //credentials: "same-origin",
            cache: 'no-cache',
        };
        options.body = body;
        const response = await fetch(url, options);
        return response.json();
    }

    async queryImages(galleryName) {
        console.log("galleryName", galleryName);
        const gqlQueryGalleryImages = {
            "variables":{"where":{"gallery":{"name":{"equals":galleryName}}}, orderBy: [{order: "asc"}]},
            "query":"query ($where: GalleryImageWhereInput  $orderBy: [GalleryImageOrderByInput!]) {items: galleryImages(where: $where, orderBy: $orderBy) {id, filename, height, width, orientation, hash, hero, order} count: galleryImagesCount(where: $where)}"
        }

        return this.postData(JSON.stringify(gqlQueryGalleryImages)).then((response) => {
            return this.simplifyQueryImagesResponse(response); 
        });
    }

    simplifyQueryImagesResponse(response) {
        const json = response;
        const result = [];
        for (const image of json.data.items) {
            result.push(image);
        }
        return result;
    }

    async updateOrder(id, order) {
        const gqlUpdateGalleryImage = {
            "variables":{"id": id, "order": order},
            "query":"mutation ($id: ID!, $order: Int) {\n  updateGalleryImage(where: {id: $id}, data: {order: $order}) {id}}"
        };

        return this.postData(JSON.stringify(gqlUpdateGalleryImage)).then((response) => {
            console.log(response); 
        });
    }

    async updateHero(id, hero) {
        const gqlUpdateGalleryImage = {
            "variables":{"id": id, "hero": hero},
            "query":"mutation ($id: ID!, $hero: Boolean) {\n  updateGalleryImage(where: {id: $id}, data: {hero: $hero}) {id}}"
        };

        return this.postData(JSON.stringify(gqlUpdateGalleryImage)).then((response) => {
            console.log(response); 
        });
    }

    async queryGalleries() {
        const gqlQueryGalleries = {
            query: "query{galleries(where: {status: {equals: \"published\"}}) {id, name, images(where: { hero: { equals: true } }, take: 1, orderBy: [{ order: asc }]) {id,filename}}}"
        }
        return this.postData(JSON.stringify(gqlQueryGalleries)).then((response) => {
            console.log(response); 
            return response;
        });
    }

    async queryLoginState() {
        const gqlQuery = {
            query: "query{users{id}}"
        }
        return this.postData(JSON.stringify(gqlQuery)).then((response) => {
            console.log(response);
            console.log(response.data.users.length);
            return response.data.users.length > 0;
        });
    }
        
}

export { KeystoneData, keystoneHost as KeystoneHost }
