import {nanoid} from 'nanoid'

class BrickLayout {

    constructor(imageList) {
        this.setImageList(imageList);
    }

    setImageList(imageList) {
        /* Preserve an unannotated image list, so we can save a new input list. We will apply reorder to this list. */
        this.inputImageList = imageList;
        this.imageList = JSON.parse(JSON.stringify(imageList));
    }

    annotateBricks() {
        for (let i=0; i<this.imageList.length; i++) {
            this.imageList[i].id = this.imageList[i].id || nanoid();
            this.imageList[i].fullAspect = this.imageList[i].width/this.imageList[i].height;
            if (this.imageList[i]['hero']) { 
                this.imageList[i].aspect = "1/1"
                this.imageList[i].className = "brick_square";
            // 4/5 Portrait
            } else if (this.imageList[i].orientation == "portrait") {
                this.imageList[i].aspect = "4/5";
                this.imageList[i].className = "brick_portrait";
            // 16/10 Landscape
            } else  {
                this.imageList[i].aspect = "16/10"
                this.imageList[i].className = "brick_landscape";
            }
        }
    
        //const img_host = "http://192.168.86.77:8002/images/";
        //const img_host_thumbnail = "http://192.168.86.77:8002/images/thumbnails/";
        const img_host = "https://img.kylescholz.com/images/";
        const img_host_thumbnail = "https://img.kylescholz.com/images/thumbnails/";
        for (let i=0; i<this.imageList.length; i++) {
            this.imageList[i].thumb_url = img_host_thumbnail + this.imageList[i].filename;
            this.imageList[i].url = img_host + this.imageList[i].filename;
        }
    }

    swap(idx1, idx2) {
        const img1 = this.imageList[idx1];
        const img2 = this.imageList[idx2];
        this.imageList[idx1] = img2;
        this.imageList[idx2] = img1;

        const iimg1 = this.inputImageList[idx1];
        const iimg2 = this.inputImageList[idx2];
        this.inputImageList[idx1] = iimg2;
        this.inputImageList[idx2] = iimg1;
    }

    // Best effort to order images to fill space with minimum empoty blocks.
    // This is optimized for 4 column layout. We could implement alternatives for 3 and 2.
    sortBricks() {
        let index = 0;
        let done = false;

        if (this.imageList.length==0) return;

        while (!done) {
            // Prefer a SQ image in upper left corner
            if (index == 0 && this.imageList[index].aspect != "1/1") {
                for (let i=index+1; i<this.imageList.length; i++) {
                    if (this.imageList[i].aspect == "1/1") {
                        this.swap(index, i);
                        break;
                    }
                }
            }
            if (index > 0) {
                // If prior brick aspect ratio is SQ, want P or W
                if (this.imageList[index-1].aspect == "1/1") {
                    // Swap if current is SQ
                    if (this.imageList[index].aspect == "1/1") {
                        for (let i=index+1; i<this.imageList.length; i++) {
                            if (this.imageList[i].aspect != "1/1") {
                                this.swap(index, i);
                                break;
                            }
                        }
                    }
                // If prior brick aspect ration is P, want to make sure there is an even number of Ps, or we'll have a hole
                } else if (this.imageList[index-1].aspect == "4/5") {
                    let count45 = 0;
                    for (let i=index-1; i>=0; i--) {
                        if (this.imageList[i].aspect == "4/5") {
                            count45++;
                        } else {
                            break;
                        }
                    }
                    // If there's an odd number of P's, this brick aspect ratio should be P
                    if (count45 % 2 != 0) {
                        if (this.imageList[index].aspect != "4/5") {
                            for (let i=index+1; i<this.imageList.length; i++) {
                                if (this.imageList[i].aspect == "4/5") {
                                    this.swap(index, i);
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            index++;
            if (index >= this.imageList.length) done = true;
        }
    }

    getBricks() {
        return this.imageList;
    }

    randomizeBricks() {
        this.imageList = this.imageList.sort((a, b) => 0.5 - Math.random());
    }
}

export default BrickLayout