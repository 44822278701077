import React, {useState, useEffect} from 'react'
import {Blurhash, BlurhashCanvas} from "react-blurhash";
import { KeystoneData, KeystoneHost } from './KeystoneData';
import BrickLayout from './Layout.js';
import { SettingOutlined, SaveOutlined, LoginOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Link } from "react-router-dom";

const source = new KeystoneData();

const Modes = {
	view: "VIEW",
	edit: "EDIT",
}

function Image({img, mode, selected, handleSelect}) {
    return(
        <div className={["masonry-item", img.className, mode==Modes.edit ? "masonry-item-edit" : "", selected ? "masonry-item-edit-selected" : ""].join(" ")} 
            onClick={(e)=>handleSelect(e, img)}
        >
            <Blurhash
                hash={img.hash}
                height="100%"
                width="100%"
            />
            <img src={img.thumb_url} className={["masonry-content", img.className]. join(" ")}/>
        </div>
    )
}

function LightboxImageBlur({imageToShow}) {
    return(
        <div id="lightbox-wrapper" style={{
            width: imageToShow.fullAspect >= 1 ? "95%": 95*imageToShow.fullAspect + "%",
            position:"relative",
            aspectRatio:imageToShow.fullAspect,
        }}>
            <BlurhashCanvas
                hash={imageToShow.hash}
                height={4} width={4} punch={1}
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                }}
            />
            <img id="lightbox-img"
                src={imageToShow.url}
                style={{
                    position:"absolute",top:0,left:0,zIndex:1,
                    display:"block",
                    width:"100%",height:"100%"
                }}
            ></img>
        </div>

   )
}

function LightboxImage({imageToShow}) {
    return(
        <div id="lightbox-wrapper" style={{
            aspectRatio:imageToShow.fullAspect,
            maxHeight: "95%",
            maxWidth: "95%",
        }}>
            <img id="lightbox-img"
                className={"loading"}
                src={imageToShow.url}
                style={{
                    display:"block",
                    width:"100%",height:"100%"
                }}
            ></img>
        </div>
   )
}

async function fetchImages({ queryKey: [_key, { galleryName }] }) {
    return await source.queryImages(galleryName).then((imgs) => {
        const layout = new BrickLayout(imgs);
        layout.annotateBricks();
        layout.sortBricks();

        return layout;
    });
}

async function fetchLoginState() {
    return await source.queryLoginState();
}

function Gallery({galleryName}) {
    if (!galleryName) galleryName = "Insects";

    const [name, setName] = useState(galleryName);
    const [mode, setMode] = useState(Modes.view);
    const [lightboxDisplay, setLightBoxDisplay] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const { status:layoutStatus, data:layout, error:layoutError, refetch:layoutRefetch } = useQuery(['gallery', {galleryName}], fetchImages);
    const { status:loggedInStatus, data:loggedIn, error:loggedInError } = useQuery(['loggedIn', {}], fetchLoginState, {
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 30,
        staleTime: 1
    });

    function showLightbox(e, img, index) {
        setSelectedIndex(index);
        setLightBoxDisplay(true);
        window.location.hash = "#lightbox";
    };

    const hideLightBox = () => {
        setLightBoxDisplay(false)
        window.history.back();
    };

    window.addEventListener('hashchange', (event) => {
        if (window.location.hash == "" || window.location.hash == "#") {
            setLightBoxDisplay(false);
        } else if (window.location.hash == "#lightbox") {
            setLightBoxDisplay(true);
        }
    });

    function toggleMode() {
        if (mode == Modes.view) {
            setMode(Modes.edit);
        } else {
            setMode(Modes.view);
        }
    };

    function selectImage(e, img, index) {
        e.stopPropagation();
        // "double-tap" to toggle hero property
        if (index == selectedIndex) {
            layout.imageList[index].hero = !layout.imageList[index].hero;
            layout.annotateBricks();
            layout.sortBricks();

            setSelectedIndex(-1);
            return
        }
        // "single-tap" a pair to swap
        if (selectedIndex != -1) {
            layout.swap(selectedIndex, index);
            layout.sortBricks();
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(index);
        }
    };

    function clearSelected() {
        if (mode == Modes.edit) {
            setSelectedIndex(-1);
        }
    };

    async function saveEdit() {
        // TODO: Can await Promise.all so these can run in parallel.
        for (let i=0; i<layout.imageList.length; i++) {
            if (layout.imageList[i].order != i) {
                await source.updateOrder(layout.imageList[i].id, i);
             }
             if (layout.imageList[i].hero != layout.inputImageList[i].hero) {
                await source.updateHero(layout.imageList[i].id, layout.imageList[i].hero);
             }
        }
        setMode(Modes.view);
        // TODO: Evict cache and force refetch
        layoutRefetch()
    }

    function goTo(url) {
        window.open(url, "_new");
    }
    
    return (
        <div className="container" onClick={() => clearSelected()}>
        { layoutStatus === 'success' ? 
            <div className="wrapper">
                <div className="sticky-header">
                    <Link className="nav_title nav_link" to="/galleryList">Photo Galleries</Link>
                    <span className="nav_title" style={{display:"inline-block"}}>: {name}</span>
                    <div style={{float:"right", display:"inline-block", padding:"4px 16px 4px 16px"}}>
                        { mode==Modes.edit ?
                            <span>
                            <LoginOutlined className="ant-icon" style={{marginRight:"10px",color:loggedIn?"white":"red"}} onClick={()=>goTo(KeystoneHost + "/signin")}/>
                            <SaveOutlined className="ant-icon" style={{marginRight:"10px",color:loggedIn?"white":"red"}} onClick={saveEdit}/>
                            </span>
                        : "" }
                        <SettingOutlined className="ant-icon" onClick={toggleMode}/>

                    </div>
                </div>
                <div className="masonry">
                    {layout.getBricks().map((img, index) => (
                        <Image key={img.id} img={img} mode={mode} selected={index == selectedIndex}
                            handleSelect={mode==Modes.view?(e)=>showLightbox(e, img, index):(e)=>selectImage(e, img, index)}
                        />
                    ))}
                </div>
            </div>

        : '' }

        { lightboxDisplay ?
            <div id="lightbox" onClick={mode==Modes.view&&hideLightBox} >
                <LightboxImage imageToShow={layout.getBricks()[selectedIndex]}/>
            </div>
        : '' }

        </div>
    );

}

export default Gallery
